import React from 'react';
import LoginForm from '../components/LoginForm';
import Link from 'next/link';
import ResponsivePageLayout from '../components/layouts/ResponsivePageLayout';
import { UserRequirement, LegalInfoOption, NextPageWithLayout } from '../types';

const Login: NextPageWithLayout = () => {
  return (
    <div className="mx-auto w-[295px] dark:bg-black-0 sm:w-[400px]">
      <h1 className="text-center text-2xl font-bold leading-compact dark:text-white">
        Enter your phone number to sign in
      </h1>
      <div data-testid="login-form" className="mb-6 mt-8">
        <LoginForm />
      </div>
      <p data-testid="help-message" className="text-center text-xs leading-compact text-gray-3">
        Jam sends daily audio playlists via SMS. You have the option to opt out and not receive messages. Message and
        data rates may apply. Reply HELP for help or STOP to cancel.&nbsp;
        <Link href="/sms-terms" className="text-plum-1 hover:underline dark:text-dragonfruit-1">
          Jam SMS Terms
        </Link>
      </p>
    </div>
  );
};

Login.getLayout = (page) => {
  return (
    <ResponsivePageLayout
      title="Sign In"
      showLegalFooter={LegalInfoOption.Always}
      hideNavLinks={true}
      verticalAlignment="center"
    >
      {page}
    </ResponsivePageLayout>
  );
};

Login.userRequirement = UserRequirement.None;

export default Login;
