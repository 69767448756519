import { useCallback, useState } from 'react';

import { FieldHookConfig, useField } from 'formik';

import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';
import 'intl-tel-input/build/css/intlTelInput.css';

const SUPPORTED_COUNTRIES = ['au', 'ca', 'ie', 'nz', 'pr', 'gb', 'us'];

const I18nPhoneField = (
  props: FieldHookConfig<string> & { testId: string; autoComplete?: 'on' | 'off'; hiddenName: string },
) => {
  const [phoneValidator, setPhoneValidator] = useState<intlTelInput.Plugin | null>(null);

  const [hiddenField, hiddenMeta, hiddenHelpers] = useField({
    name: props.hiddenName,
  });

  const [field, meta, helpers] = useField({
    ...props,
    validate: () => {
      const validated: boolean = phoneValidator !== null && phoneValidator.isValidNumber();
      if (validated && phoneValidator) {
        const number: string = phoneValidator.getNumber(intlTelInputUtils.numberFormat.NATIONAL);
        const e164 = phoneValidator.getNumber(intlTelInputUtils.numberFormat.E164);
        helpers.setValue(number, false);
        hiddenHelpers.setValue(e164, false);
      }
      return validated ? '' : 'Invalid';
    },
  });

  // This uses the functional form of refs (https://reactjs.org/docs/refs-and-the-dom.html#callback-refs), because
  // it allows us to initialize the intlTelInput when the component mounts and remove the ref when it unmounts.
  const inputRef = useCallback((node: HTMLInputElement) => {
    if (node != null) {
      const pv = intlTelInput(node, {
        onlyCountries: SUPPORTED_COUNTRIES,
        autoPlaceholder: 'aggressive',
      });
      setPhoneValidator(pv);
    } else {
      setPhoneValidator(null);
    }
  }, []);

  return (
    <>
      <input
        {...field}
        autoComplete={props.autoComplete}
        data-testid={props.testId}
        className={props.className}
        type="tel"
        ref={inputRef}
      />
      <input {...hiddenField} type="hidden" data-testid={props.hiddenName} />
    </>
  );
};

I18nPhoneField.displayName = 'LoginForm';
export default I18nPhoneField;
