import { useCallback } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useRouter } from 'next/router';
import SpinnerIcon from '../components/icons/Spinner';

import { requestLoginOtp } from '../lib/auth';
import I18nPhoneField from './I18nPhoneField';

import type { I18nPhoneFormValues } from '../types';

const LoginForm = () => {
  const router = useRouter();

  const handleSubmit = useCallback(
    async (values: I18nPhoneFormValues, { setSubmitting }: FormikHelpers<I18nPhoneFormValues>) => {
      const accepted = await requestLoginOtp(values.phoneE164);
      if (accepted) {
        router.push({ pathname: '/login/verify', query: { phone: values.phoneE164 } });
      }
      setSubmitting(false);
    },
    [router],
  );

  return (
    <Formik initialValues={{ phone: '', phoneE164: '' }} validateOnChange={false} onSubmit={handleSubmit}>
      {({ errors, isSubmitting }) => (
        <Form className="flex flex-col gap-4 dark:bg-black-0">
          {errors.phone && (
            <p className="flex h-10 w-full items-center justify-center rounded-xl bg-strawberry-2 text-sm text-strawberry-1">
              Phone number is required
            </p>
          )}
          <label htmlFor="phone-number" className="sr-only">
            Phone number
          </label>

          <I18nPhoneField
            name="phone"
            hiddenName="phoneE164"
            className="w-full rounded-xl placeholder-gray-4 outline-none focus:outline-none focus:ring-plum-1 dark:bg-black-0 dark:text-white dark:placeholder-gray-3 dark:focus:bg-black-0"
            testId="phone-field"
            autoComplete="on"
          />

          {isSubmitting ? (
            <button
              type="submit"
              disabled={isSubmitting}
              className="relative flex w-full justify-center rounded-xl border border-transparent bg-plum-2 px-4 py-2 text-sm font-bold focus:outline-none focus:ring-2 focus:ring-plum-2 focus:ring-offset-2 dark:border-black-0 dark:bg-dragonfruit-1/30 dark:focus:outline-none dark:focus:ring-dragonfruit-1"
            >
              <SpinnerIcon
                svgClass="animate-spin dark:fill-dragonfruit-1 h-6 w-6"
                pathClass="dark:fill-dragonfruit-1"
              />
            </button>
          ) : (
            <button
              type="submit"
              data-testid="submit-button"
              className="flex w-full justify-center rounded-xl border border-none border-transparent bg-plum-1 px-4 py-2 text-sm font-bold text-white hover:bg-plum-1/80 focus:outline-none focus:ring-2 focus:ring-plum-2 focus:ring-offset-2 dark:bg-dragonfruit-1 dark:text-black-0 dark:hover:bg-dragonfruit-1/80 dark:focus:outline-none dark:focus:ring-2 dark:focus:ring-dragonfruit-1 dark:focus:ring-offset-2"
            >
              Continue
            </button>
          )}
        </Form>
      )}
    </Formik>
  );
};
LoginForm.displayName = 'LoginForm';
export default LoginForm;
