import { SvgFC } from '../../types';
const SpinnerIcon: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" className={svgClass} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Spinner</title>
    <path
      opacity="0.1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      fill="#6900BC"
      className={pathClass}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2C11 1.44772 11.4477 1 12 1C18.0751 1 23 5.92487 23 12C23 12.5523 22.5523 13 22 13C21.4477 13 21 12.5523 21 12C21 7.02944 16.9706 3 12 3C11.4477 3 11 2.55228 11 2Z"
      fill="#6900BC"
      className={pathClass}
    />
  </svg>
);
export default SpinnerIcon;
